import { faClose, faGift } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import axios from "axios";
import { sendVerifyEmail } from "../services/user.service";
import { getCurrentUser, login } from "../services/auth.service";
import { useNavigate } from "react-router-dom";

import { loadStripe } from "@stripe/stripe-js";
const stripePromise = await loadStripe(
  "pk_test_51Odyf8Edv3TCDl3hlFwXcV6hDLGbTYaz0kO6N3H3z3FwKAlj8Qxr6xfHEMoxF2MXedAg6QDDscZDCkIA1Pe01OUe004yrgcAoI"
);

const PaymentModal = ({ showModal, setShowModal }) => {
  const navigate = useNavigate();

  const handleStart = (metho) => {
    //fetch("https://test.chatnet.ai/v1/checkout", {
    fetch(`${process.env.REACT_APP_API_ROOT}/v1/checkout`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        email: getCurrentUser().user.email,
      },
      body: JSON.stringify({
        method: metho,
        customerID: getCurrentUser().user.billingID,
      }),
    })
      .then((result) => result.json())
      .then(({ sessionId }) => {
        //console.log(sessionId);
        stripePromise.redirectToCheckout({ sessionId });
      });
  };
  const benefits = [
    {
      title: "Unlimited Copilot Queries",
      content:
        "Copilot is your interactive research assistant for thorough answers to complex questions. (300+/day)",
    },
    {
      title: "Unlimited File Uploads",
      content:
        "Ask about images, documents, and more, powered by models like Claude 2.1 and GPT-4V.",
    },
    {
      title: "AI Model Selection",
      content:
        "Choose from the latest AI models like GPT-4, Claude 2.1, and Perplexity for improved answers.",
    },
    {
      title: "API Credits",
      content:
        "Enjoy $5 monthly credit for our text generation API. Perplexity's online LLM offers up-to-date information at low latency.",
    },
  ];
  return (
    <>
      {showModal ? (
        <>
          <div className="fixed inset-0 z-40 flex items-center justify-center overflow-x-hidden overflow-y-auto bg-[#303030A0] outline-none focus:outline-none">
            <div className="relative z-50 w-5/6 max-w-2xl mx-auto my-6 sm:w-4/5 lg:w-1/2 md:w-2/3">
              <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                <div className="flex items-start justify-between px-2 py-2 border-gray-300 border-solid">
                  <h3 className="text-2xl"></h3>
                  <button
                    className="float-right text-black bg-transparent border-0"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="block w-6 h-6 py-0 text-xl text-black rounded-full opacity-7">
                      <FontAwesomeIcon icon={faClose} />
                    </span>
                  </button>
                </div>
                <div className="relative items-center justify-center flex-auto">
                  <h1 className="text-3xl text-[#20808D] text-center my-12">
                    Chatnet.ai
                    <span className="ml-4 bg-[#20808D] text-white px-2 rounded-lg">
                      Pro
                    </span>
                  </h1>
                  <p className="hidden mb-6 text-xl text-center">
                    Sign in to continue
                  </p>
                  {/* <form className="w-full px-8 pt-6 pb-8 rounded shadow-md"> */}
                  <div className="grid grid-cols-2 gap-6 px-6 mb-2">
                    {benefits.map((benefit, index) => (
                      <div>
                        <h1 className="mb-2 font-bold text-[#3a5b5e]">
                          {benefit.title}
                        </h1>
                        <p className="text-[#777f80]">{benefit.content}</p>
                      </div>
                    ))}
                  </div>

                  {/* </form> */}

                  <div className="flex w-full gap-5 p-5 cursor-pointer">
                    <div
                      className={` flex flex-col justify-between border-[1px] border-gray-400 rounded-md w-1/2 p-3`}
                    >
                      <div>
                        <p className={` text-xs text-[#20808D]`}>MONTHLY</p>
                        <p className={`my-1 text-xl font-bold text-[#2a4649]`}>
                          $20
                        </p>
                        <p className="text-[#757a7a]">billed per month</p>
                      </div>
                      <button
                        className="px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase bg-[#20808D] rounded shadow outline-none active:bg-[#86c2ca] hover:shadow-lg focus:outline-none"
                        type="button"
                        onClick={() => {
                          handleStart(0);
                        }}
                      >
                        Get Started
                      </button>
                    </div>

                    <div
                      className={`border-[1px] border-gray-400 rounded-md w-1/2 p-3 cursor-pointer flex flex-col justify-between`}
                    >
                      <div>
                        <div className="flex justify-between">
                          <p className={` text-xs text-[#20808D]`}>YEARLY</p>
                          <p className="text-[#1d4b51] text-xs bg-[rgb(232,232,229)] px-2 rounded-md hidden md:block">
                            Save $40 a year
                          </p>
                        </div>
                        <p className={`my-1 text-xl font-bold text-[#2a4649]`}>
                          $200
                        </p>
                        <p className="text-[#757a7a]">billed per year</p>

                        <p className={` text-sm my-3 text-[#20808D]`}>
                          <FontAwesomeIcon icon={faGift} />
                          &nbsp;&nbsp;7 day free trial
                        </p>
                      </div>
                      <button
                        className="px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase bg-[#20808D] rounded shadow outline-none active:bg-[#86c2ca] hover:shadow-lg focus:outline-none"
                        type="button"
                        onClick={() => {
                          handleStart(1);
                        }}
                      >
                        Get Started
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default PaymentModal;
