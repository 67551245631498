import {
  faCompass,
  faHistory,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { getCurrentUser } from "../services/auth.service";
import { getUserSessions, updateUser } from "../services/user.service";
import { useNavigate } from "react-router-dom";
import { deleteSession } from "../services/session.service";
export default function History({
  setCurrentSession,
  setUpdateConversation,
  setUpdateSidebar,
}) {
  const [sessions, setSessions] = useState([]);
  const navigate = useNavigate();
  const [updateSessions, setUpdateSessions] = useState(0);
  useEffect(() => {
    if (getCurrentUser())
      getUserSessions(getCurrentUser().user.id).then((response) => {
        setSessions(response.data);
      });
  }, [updateSessions]);
  return (
    <div className="max-w-5xl mx-auto text-[#13343b] max-md:mb-20">
      <h1 className="mt-10 text-4xl ">
        <FontAwesomeIcon icon={faHistory} />
        &nbsp;&nbsp;History
      </h1>
      <hr className="border-[#AEC6CF] mt-10 border-[1px] w-full" />
      {sessions
        .filter((session) => session.title !== "@@@@@")
        .map((session, index) => (
          <div
            key={index}
            className={` flex justify-between p-4 ${
              index % 2 === 1 ? "bg-[#ddecee]" : "bg-white"
            }`}
          >
            <h1
              className="hover:cursor-pointer"
              onClick={async () => {
                await updateUser(getCurrentUser().user.id, {
                  currentSessionId: session.id,
                });
                setCurrentSession(session.id);
                //setUpdateConversation(Math.random());
                navigate("/conversation");
                // window.location.reload();
              }}
            >
              {session.title}
            </h1>
            <FontAwesomeIcon
              icon={faTrash}
              className="hover:cursor-pointer"
              onClick={async () => {
                await deleteSession(session.id);
                setUpdateSessions(Math.random());
                setUpdateSidebar(Math.random());
              }}
            />
          </div>
        ))}
    </div>
  );
}
