import {
  faClock,
  faCompass,
  faCompassDrafting,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import {
  createDiscover,
  deleteDiscover,
  getDiscovers,
} from "../services/discover.service";
import { createSession } from "../services/session.service";
import { updateUser } from "../services/user.service";
import { getCurrentUser } from "../services/auth.service";
import { createConversation, getProjects } from "../services/openai.service";
import { DiscoverTable } from "../components/DiscoverTable";
import { Pagination } from "flowbite-react";
export default function Discover({
  setCurrentSession,
  setUpdateSidebar,
  setUpdateConversation,
}) {
  const [userData, setUserData] = useState([]);
  // const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState("");
  const [total, setTotal] = useState(0);

  const navigate = useNavigate();
  const [discovers, setDiscovers] = useState([]);
  const [projects, setProjects] = useState([]);
  const [updateView, setUpdateView] = useState(0);
  useEffect(() => {
    getDiscovers().then((response) => {
      setDiscovers(response);
      //console.log(response);
    });

    // getProjects(currentPage).then((response) => {
    //   tmpDiscovers.concat(response.data.data);
    //   setTotal(response.data.total);
    // });
    // setProjects(tmpDiscovers);
  }, [updateView]);

  useEffect(() => {
    let tmpDiscovers = [];

    let total = 0;
    getProjects(1).then(async (response) => {
      //tmpDiscovers.concat(response.data.data);
      tmpDiscovers = response.data.data;
      total = response.data.total;
      setTotal(response.data.total);
      setProjects(tmpDiscovers);
      for (let i = 1; i < Math.ceil(total / 10); i++) {
        let tmp = await getProjects(i + 1);
        tmpDiscovers = [...tmpDiscovers, ...tmp.data.data];
        //console.log(tmpDiscovers);
        setProjects(tmpDiscovers);
      }

      setProjects(tmpDiscovers);
    });

    getDiscovers().then((response) => {
      setDiscovers(response);
      //console.log(response);
    });
  }, [filter]);

  const toggleDiscover = (id, showUser, content, image) => {
    id = parseInt(id);
    //console.log(">>> Toggle Discover");
    //console.log(discovers.filter((item) => console.log(item.projectId, id)));

    if (showUser) {
      if (discovers.filter((item) => item.projectId === id).length > 0)
        deleteDiscover(id).then(() => {
          console.log(image);
          const formData = new FormData();
          formData.append(
            "title",
            projects.filter((project) => project.id - id === 0)[0][
              "project_name"
            ]
          );
          formData.append("content", content);
          formData.append("projectId", id);
          formData.append("image", image);

          fetch(`${process.env.REACT_APP_API_ROOT}/v1/discovers/`, {
            method: "post",
            body: formData,
          })
            .then((r) => setUpdateView(Math.random()))
            .catch((err) => console.error(err));
          // createDiscover({
          //   title: projects.filter((project) => project.id - id === 0)[0][
          //     "project_name"
          //   ],
          //   content: content,
          //   projectId: id,
          //   image: image,
          // }).then(() => setUpdateView(Math.random()));
        });
      else
        createDiscover({
          title: projects.filter((project) => project.id - id === 0)[0][
            "project_name"
          ],
          content: content,
          projectId: id,
          image: image,
        }).then(() => setUpdateView(Math.random()));
    } else if (discovers.filter((item) => item.projectId === id).length > 0) {
      console.log(id);
      deleteDiscover(id).then(() => setUpdateView(Math.random()));
    }
  };
  // const onPageChange = (page) => {
  //   setCurrentPage(page);
  //   //window.location.reload();
  // };

  const goThisDiscover = (index) => {
    // if (getCurrentUser().user.plan === "none") {
    //   // eslint-disable-next-line no-restricted-globals
    //   if (confirm("Subscribe first!")) navigate("/profile");
    //   return;
    // }

    const title = discovers[index].title;
    const projectId = discovers[index].projectId;
    //console.log(title, projectId);
    createConversation(projectId, title).then(async (res) => {
      //console.log(res);

      if (getCurrentUser()) {
        createSession({
          title: "@@@@@",
          projectId: projectId,
          convId: res.data.session_id,
          userId: getCurrentUser().user.id,
        }).then(async (res) => {
          await updateUser(getCurrentUser().user.id, {
            currentSessionId: res.id,
          });
          setCurrentSession(res.id);
          setUpdateSidebar(Math.random());

          navigate("/conversation");
        });
      } else {
        localStorage.setItem("conversation", JSON.stringify(res));
        navigate("/conversation");
      }
    });
  };
  return (
    <div className="max-w-5xl mx-auto text-[#13343b] max-md:mb-20">
      <h1 className="mt-10 text-4xl">
        <FontAwesomeIcon icon={faCompass} />
        &nbsp;&nbsp;Discover<span className="hidden">{updateView}</span>
      </h1>
      <hr className="border-[#AEC6CF] mt-10 border-[1px] w-full" />
      {getCurrentUser() && getCurrentUser().user.role === "admin" ? (
        <div>
          <DiscoverTable
            discoverData={discovers}
            projectData={projects}
            toggleDiscover={toggleDiscover}
            setFilter={setFilter}
          />
          <div className="flex hidden overflow-x-auto sm:justify-center">
            {/* <Pagination
              currentPage={currentPage}
              totalPages={Math.ceil(total / 10)}
              onPageChange={onPageChange}
              className="flex"
            /> */}
          </div>
        </div>
      ) : (
        <div className="grid grid-cols-1 gap-10 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2">
          {discovers.map((discover, index) => (
            <div key={index} className="">
              <div
                className="my-3 hover:cursor-pointer"
                onClick={() => goThisDiscover(index)}
              >
                <div className="overflow-hidden">
                  <h1 className="mb-3 text-xl hover:text-[#9ddae2] trans font-semibold">
                    {discover.title}
                  </h1>
                  <p className="hidden">{discover.content}</p>
                </div>
                <img
                  src={`${process.env.REACT_APP_API_ROOT}/v1/result/${discover.image}`}
                  alt="springtra"
                  className="object-contain w-full rounded-lg shadow-md shadow-neutral-300 hover:scale-110"
                />
              </div>
            </div>
          ))}
        </div>
      )}
      <hr className="border-[#AEC6CF] border-[1px] " />
    </div>
  );
}
