import { faClose, faGift } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { sendVerifyEmail } from "../services/user.service";
import { getCurrentUser, login } from "../services/auth.service";
import { useNavigate } from "react-router-dom";
import { getUser } from "../services/user.service";
import {
  faArrowAltCircleRight,
  faArrowRight,
  faArrowRightFromFile,
  faArrowRightRotate,
  faArrowUpRightFromSquare,
  faArrowsLeftRight,
  faChessKnight,
  faCircleArrowRight,
  faCrown,
  faPrint,
  faSadCry,
} from "@fortawesome/free-solid-svg-icons";
import { useRef } from "react";
import autosize from "autosize";
import { createSession, updateSession } from "../services/session.service";

import { updateUser } from "../services/user.service";
import { useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createConversation, getResponse } from "../services/openai.service";
const NewsessionModal = ({
  showNewSession,
  setShowNewSession,
  setCurrentSession,
  setUpdateSidebar,
  setUpdateConversation,
  conversation,
  setConversation,
  setUpdateScrollView,
}) => {
  const [method, setMethod] = useState(0);
  const navigate = useNavigate();

  const handleInput = async (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      setShowNewSession(false);
      setPrompt("");
      if (!getCurrentUser()) {
        // alert("Login first!");

        createConversation(10761, e.target.value).then(async (res) => {
          localStorage.setItem("conversation", JSON.stringify(res));

          navigate("/conversation");
          const conversation = [];
          conversation.push(prompt);
          setCurrentSession(res.id);
          setUpdateSidebar(Math.random());
          setConversation(conversation);
          getResponse(prompt, res.data.project_id, res.data.session_id)
            .then((source) => {
              source.addEventListener("start", (e) => {
                const conv = conversation;
                if (conv.length % 2 === 1) conv.push("");
                setConversation(conv);
              });
              source.addEventListener("progress", (e) => {
                let conv = conversation;
                if (conv.length % 2 === 1) {
                  // console.log("Why is the length of all chats odd?");
                  return;
                }
                let payload = JSON.parse(e.data);
                conv[conv.length - 1] += payload["message"];
                setConversation(conv);
                setUpdateScrollView(Math.random());
              });
              source.addEventListener("finish", (e) => {
                //   console.log(e);
              });

              source.stream();
            })
            .catch(function (error) {
              console.error(error);
            });
        });
      } else {
        createConversation(10761, e.target.value).then(async (res) => {
          //   console.log("Auth > Home:", res);

          createSession({
            title: e.target.value,
            projectId: res.data.project_id,
            convId: res.data.session_id,
            userId: getCurrentUser().user.id,
          }).then(async (res) => {
            await updateUser(getCurrentUser().user.id, {
              currentSessionId: res.id,
            });

            const conversation = [];
            conversation.push(prompt);
            setCurrentSession(res.id);
            setUpdateSidebar(Math.random());
            getResponse(prompt, res.projectId, res.convId)
              .then((source) => {
                source.addEventListener("start", (e) => {
                  const conv = conversation;
                  if (conv.length % 2 === 1) conv.push("");
                  setConversation(conv);
                });
                source.addEventListener("progress", (e) => {
                  let conv = conversation;
                  if (conv.length % 2 === 1) {
                    //   console.log("Why is the length of all chats odd?");
                    return;
                  }
                  let payload = JSON.parse(e.data);
                  conv[conv.length - 1] += payload["message"];
                  setConversation(conv);
                  setUpdateScrollView(Math.random());
                });
                source.addEventListener("finish", (e) => {
                  //       console.log(e);
                });

                source.stream();
              })
              .catch(function (error) {
                console.error(error);
              });

            navigate("/conversation");
          });
        });
      }
    }
  };
  useEffect(() => {
    //autosize(document.querySelectorAll("textarea")[1]);
    // if (showNewSession === true)
    document.addEventListener("click", handleOutsideClick, false);
  }, []);

  const handleOutsideClick = (e) => {
    //console.log(e.target.id);
    if (e.target.id === "c") {
      setShowNewSession(false);
      setPrompt("");
    }
  };

  const [prompt, setPrompt] = useState("");
  const submitbtn = useRef(null);
  useEffect(() => {
    if (submitbtn.current !== null)
      if (prompt === "") submitbtn.current.style.color = "#999";
      else submitbtn.current.style.color = "#20808D";
  }, [prompt]);
  const handleClose = () => {
    navigate("/");
  };
  const containerRef = useRef(null);

  return (
    <>
      {showNewSession ? (
        <>
          <div
            className="fixed inset-0 z-[100] flex items-center justify-center overflow-x-hidden overflow-y-auto bg-[#101010E0] outline-none focus:outline-none trans
            "
            id="c"
          >
            <div
              className="relative z-50 w-4/5 max-w-3xl mx-auto my-6 xl:w-1/3 lg:w-1/2 sm:w-2/3"
              id="a"
            >
              <div
                className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none"
                id="b"
              >
                <div className="relative items-center justify-center flex-auto">
                  <div className="w-full mx-auto">
                    <div className="relative flex justify-between w-full px-5 mt-4 mb-4">
                      <textarea
                        className="w-full p-4 border-[#9a9e9f] border-2 rounded-lg max-h-96"
                        rows={4}
                        placeholder="Ask anything..."
                        onKeyDown={handleInput}
                        value={prompt}
                        onChange={(e) => setPrompt(e.target.value)}
                      ></textarea>

                      <FontAwesomeIcon
                        icon={faCircleArrowRight}
                        className={`absolute my-2 ml-3 text-[#999] size-7 bottom-1 right-8 hover:text-[#9ddae2] hover:cursor-pointer 
          aria-disabled:text-gray-700`}
                        //onClick={handleSubmit}
                        ref={submitbtn}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default NewsessionModal;
