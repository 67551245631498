import PaymentModal from "../modals/Payment";
import { getCurrentUser, logout } from "../services/auth.service";
import { remove } from "../services/user.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCross,
  faCrosshairs,
  faDeleteLeft,
  faEdit,
  faEraser,
  faRemove,
  faRemoveFormat,
  faSignOut,
  faSubscript,
  faTools,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
export default function Profile({ showPayment, setShowPayment }) {
  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem("user");
    navigate("/");
    document.location.reload();
  };
  const deleteAccount = () => {
    if (getCurrentUser()) remove(getCurrentUser().user.id);
    logout();
  };
  const emailVerify = () => {};
  return (
    <div className="max-w-5xl mx-auto text-[#64645F]">
      <h1 className="mt-10 text-4xl">
        <FontAwesomeIcon icon={faTools} />
        &nbsp;&nbsp;Settings
      </h1>
      <hr className="border-[#AEC6CF] my-10 border-[1px] w-full" />

      <h1 className="text-xl font-bold">Account</h1>
      <hr className="border-[#749DAD] my-3 border-[1px] w-full" />
      <div className="bg-[#749DAD] p-3 space-y-3 rounded-lg text-white">
        <div className="flex justify-between p-2">
          <span>Username</span>
          <span>{getCurrentUser() && getCurrentUser().user.name}</span>
        </div>

        <div className="flex justify-between p-2">
          <span>Email</span>
          <span>{getCurrentUser() && getCurrentUser().user.email}</span>
        </div>

        <div className="justify-between hidden p-2">
          <span>Email Verifyed</span>
          <span>
            {getCurrentUser() &&
              (getCurrentUser().user.isEmailVerified
                ? "Verified"
                : "Not verified")}
            {getCurrentUser() &&
              getCurrentUser().user.isEmailVerified === false && (
                <button
                  onClick={emailVerify}
                  className="inline px-6 ml-2 font-bold text-white bg-[#20808D] rounded-full shadow outline-none active:bg-[#5a9ba3] hover:shadow-lg focus:outline-none"
                >
                  Verify
                </button>
              )}
          </span>
        </div>
      </div>

      <h1 className="mt-4 text-xl font-bold">
        Chatnet{" "}
        <span className="bg-[#20808D] text-white p-1 rounded-md">Pro</span>
      </h1>
      <hr className="border-[rgb(234,234,227)] my-3 border-[1px] w-full" />

      <div className="bg-[#749DAD] p-3 space-y-3 mt-5  rounded-lg text-white">
        <div className="flex justify-between">
          <span className="my-2">Subscription</span>
          <span>
            <button
              className="inline px-6 ml-2 h-full font-bold  hover:bg-[#d0d5d6] rounded-md trans bg-[#e4e6e7 text-[#749DAD]]"
              type="button"
              onClick={() => setShowPayment(true)}
            >
              Pay now
            </button>
          </span>
        </div>
      </div>

      <div className="bg-[#749DAD] p-3 space-y-3 mt-5  rounded-lg text-white">
        <div className="flex justify-between">
          <span className="my-2">
            <FontAwesomeIcon icon={faEdit} />
            &nbsp;&nbsp;Give us feedback
          </span>
          <span className="">
            <button
              onClick={logout}
              className="inline px-3 ml-2 h-full font-bold  hover:bg-[#d0d5d6] rounded-md trans"
            >
              <span className="max-md:hidden">Sign out</span>
              <FontAwesomeIcon icon={faSignOut} className="md:hidden" />
            </button>

            <button
              className="inline px-3 ml-2 h-full font-bold  hover:enabled:bg-[#d0d5d6] rounded-md trans"
              onClick={deleteAccount}
              disabled={
                getCurrentUser() && getCurrentUser().user.role === "admin"
              }
            >
              <span className="max-md:hidden">Delete account</span>
              <FontAwesomeIcon icon={faRemove} className="md:hidden" />
            </button>
          </span>
        </div>
      </div>

      {/*
      <Elements stripe={stripePromise}>
        <CheckoutForm />
      </Elements> */}
    </div>
  );
}
