import axios from "axios";

//const SESSION_URL = "https://test.chatnet.ai/v1/sessions/";
const SESSION_URL = `${process.env.REACT_APP_API_ROOT}/v1/sessions/`;

export const getSessions = async (userId) => {
  try {
    const response = await axios.get(SESSION_URL, {
      body: {
        userId: userId,
      },
    });
    return response.data;
  } catch (error) {
    // Handle errors appropriately (e.g., logging, displaying error messages)
    console.error("Error fetching sessions:", error);
    throw error;
  }
};

export const getSession = async (id) => {
  try {
    const response = await axios.get(SESSION_URL + id);
    return response.data;
  } catch (error) {
    // Handle errors appropriately (e.g., logging, displaying error messages)
    console.error("Error fetching sessions:", error);
    throw error;
  }
};

export const createSession = async (data) => {
  try {
    const response = await axios.post(SESSION_URL, data);
    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};

export const updateSession = async (id, data) => {
  try {
    const response = await axios.patch(SESSION_URL + id + "/", data);
    return response.data;
  } catch (error) {
    console.error("Error updating session:", error);
    throw error;
  }
};

export const deleteSession = async (id) => {
  try {
    const response = await axios.delete(SESSION_URL + id + "/");
    return response.data;
  } catch (error) {
    console.error("Error deleting session:", error);
    throw error;
  }
};
