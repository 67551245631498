import axios from "axios";

//const DISCOVER_URL = "https://test.chatnet.ai/v1/discovers/";
const DISCOVER_URL = `${process.env.REACT_APP_API_ROOT}/v1/discovers/`;

export const getDiscovers = async () => {
  try {
    const response = await axios.get(DISCOVER_URL);
    return response.data;
  } catch (error) {
    // Handle errors appropriately (e.g., logging, displaying error messages)
    console.error("Error fetching discovers:", error);
    throw error;
  }
};

export const getDiscover = async (id) => {
  try {
    const response = await axios.get(DISCOVER_URL + id);
    return response.data;
  } catch (error) {
    // Handle errors appropriately (e.g., logging, displaying error messages)
    console.error("Error fetching sessions:", error);
    throw error;
  }
};

export const createDiscover = async (data) => {
  try {
    const response = await axios.post(DISCOVER_URL, data);
    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};

export const deleteDiscover = async (id) => {
  return axios.delete(DISCOVER_URL + id);
};
