import { faAdd, faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useState } from "react";

export default function UpdateProjectModal({
  id,
  project,
  toggleDiscover,
  initContent,
  type,
  defaultImage,
}) {
  const [showModal, setShowModal] = useState(false);
  const [showUser, setShowUser] = useState(false);
  const [content, setContent] = useState(initContent);
  const [file, setFile] = useState(defaultImage);
  function handleChange(event) {
    setFile(event.target.files[0]);
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    toggleDiscover(id, true, content.length > 0 ? content : " ", file);
    // const formData = new FormData();
    // formData.append("carname", carname);
    // formData.append("per_20days", per_20days);
    // formData.append("per_10days", per_10days);
    // formData.append("per_day", per_day);
    // if (typeof file !== typeof {}) {
    //   const url = `http://35.173.248.65/api/v1/car/file/${file}`;
    //   fetch(url).then(async (response) => {
    //     const contentType = response.headers.get("content-type");
    //     const blob = await response.blob();
    //     setFile(new File([blob], file, { contentType }));
    //     // access file here
    //   });
    // }
    // formData.append("carimg", file);

    // if (default_button_text === "Add") {
    //   await fetch("http://35.173.248.65/api/v1/car/create", {
    //     method: "post",
    //     body: formData,
    //   })
    //     .then((r) => console.log(r.json()))
    //     .catch((err) => console.error(err));
    // } else {
    //   formData.append("id", default_id);
    //   await fetch("http://35.173.248.65/api/v1/car/update", {
    //     method: "post",
    //     body: formData,
    //   })
    //     .then((r) => console.log(r.json()))
    //     .catch((err) => console.error(err));
    // }
    setShowModal(false);
    setContent("");
    setShowUser(false);
    //window.location.reload();
  };

  return (
    <div>
      <FontAwesomeIcon
        icon={type === 0 ? faEdit : faAdd}
        onClick={() => {
          console.log(defaultImage);
          setFile(defaultImage);
          if (type === 0) {
            setShowModal(true);
            setContent(initContent);
          } else toggleDiscover(id, true, " ", "default.jpg");
        }}
        className="hover:text-blue-600 hover:cursor-pointer size-6"
      />

      {showModal && (
        <div
          id="editUserModal"
          tabIndex="-1"
          aria-hidden="true"
          className="fixed top-0 left-0 right-0 z-50 items-center justify-center w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full bg-[#20202020]"
        >
          <div className="relative w-full max-w-2xl max-h-full mx-auto mt-36">
            <form
              className="relative bg-white rounded-lg shadow dark:bg-gray-700"
              onSubmit={handleSubmit}
            >
              <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                  {id}&nbsp;&nbsp;{project}
                </h3>
                <button
                  type="button"
                  className="inline-flex items-center justify-center w-8 h-8 text-sm text-gray-400 bg-transparent rounded-lg hover:bg-gray-200 hover:text-gray-900 ms-auto dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-hide="editUserModal"
                  onClick={() => setShowModal(false)}
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              <div className="p-6 space-y-6">
                <div className="grid grid-cols-4 gap-5">
                  <div className="col-span-2 ">
                    <label
                      htmlFor="carimg"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Discover Image
                    </label>
                    <img
                      src={
                        typeof file === typeof {}
                          ? URL.createObjectURL(file)
                          : file
                      }
                      alt="sdf"
                      className="object-contain w-full m-2 align-top bg-gray-200 aspect-square"
                    />
                    <input
                      type="file"
                      name="carimg"
                      id="carimg"
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      required=""
                      onChange={handleChange}
                      alt="carimg"
                      accept="image/*"
                    />
                  </div>
                  <div className="col-span-2 gap-5">
                    <label
                      htmlFor="carname"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Content
                    </label>
                    <textarea
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Type content here..."
                      value={content}
                      onChange={(e) => setContent(e.target.value)}
                      rows={14}
                    ></textarea>
                    <div className="flex items-center justify-between mt-7 ">
                      <label
                        className="inline-flex items-center invisible cursor-pointer"
                        onMouseUp={() => {
                          //console.log(showUser);
                          setShowUser(!showUser);
                        }}
                      >
                        <input
                          type="checkbox"
                          value={showUser}
                          className="hidden sr-only peer"
                        />
                        <div
                          className="relative w-11 h-6 bg-gray-200 
                      peer-focus:outline-none 
                      peer-focus:ring-4 
                      peer-focus:ring-blue-300 
                      
                      rounded-full peer 
                      
                      peer-checked:after:translate-x-full 
                      
                      rtl:peer-checked:after:-translate-x-full 
                      peer-checked:after:border-white 
                      after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 
                      after:border after:rounded-full after:h-5 after:w-5 after:transition-all 
                      
                      peer-checked:bg-blue-600"
                        ></div>
                        <span className="text-sm font-medium text-gray-900 ms-3 dark:text-gray-300">
                          Show users
                        </span>
                      </label>

                      <button
                        type="submit"
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                      >
                        {type === 0 ? "Update" : "Add"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}
