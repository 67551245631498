import UserTable from "../components/UserTable";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Pagination } from "flowbite-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCompass, faUsers } from "@fortawesome/free-solid-svg-icons";

const UsersContext = React.createContext({
  users: [],
  fetchUsers: () => {},
});

export default function UsersPage() {
  const [userData, setUserData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);

  const fetchUsers = async (pageIndex) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_ROOT}/v1/users?page=${pageIndex}&size=10`
      //`https://test.chatnet.ai/v1/users?page=${pageIndex}&size=10`
    );
    const users = await response.json();
    setUserData(users.results);
    setTotal(users.totalResults);
  };
  useEffect(() => {
    fetchUsers(currentPage);
  }, [currentPage]);

  const onPageChange = (page) => {
    setCurrentPage(page);
    //window.location.reload();
  };

  return (
    <UsersContext.Provider value={{ userData, fetchUsers }}>
      {/* <div className="w-full h-full "> */}
      <div className="w-full h-full bg-white text-[#13343b]">
        <div className="max-w-5xl mx-auto">
          <h1 className="mt-10 text-4xl">
            <FontAwesomeIcon icon={faUsers} />
            &nbsp;&nbsp;Users
          </h1>
          <hr className="border-[#AEC6CF] mt-10 border-[1px] w-full" />
          <UserTable userData={userData} />
          <div className="flex overflow-x-auto sm:justify-center">
            <Pagination
              currentPage={currentPage}
              totalPages={Math.ceil(total / 10)}
              onPageChange={onPageChange}
              className="flex"
            />
          </div>
        </div>
      </div>
    </UsersContext.Provider>
  );
}
