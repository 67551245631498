import React from "react";
import { marked } from "marked";
import DOMPurify from "dompurify";
import "./MarkdownRenderer.css";

export default function MarkdownRenderer({ markdownText }) {
  // Convert Markdown to HTML
  const createMarkup = (markdown) => {
    const rawMarkup = marked(markdown, { sanitize: true });
    const sanitizedMarkup = DOMPurify.sanitize(rawMarkup);
    //console.log(sanitizedMarkup);

    return { __html: sanitizedMarkup };
  };

  return (
    <div
      className="markdown-content "
      dangerouslySetInnerHTML={createMarkup(markdownText)}
    />
  );
}
