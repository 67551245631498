import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faList,
  faHistory,
  faUsers,
  faToolbox,
  faTools,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { getCurrentUser } from "../services/auth.service";
export default function Footbar() {
  const navigate = useNavigate();
  return (
    <div className="fixed left-0 right-0 flex items-center justify-center h-20 bottom-0 bg-[#AEC6CF] md:hidden text-center w-full">
      {getCurrentUser() && getCurrentUser().user.role === "admin" ? (
        <div
          className="w-1/3 text-center sidebar-link trans "
          onClick={() => navigate("/")}
        >
          <FontAwesomeIcon icon={faUsers} />
          &nbsp; Users
        </div>
      ) : (
        <div
          className="w-1/3 text-center sidebar-link trans "
          onClick={() => navigate("/")}
        >
          <FontAwesomeIcon icon={faHome} />
          &nbsp; Home
        </div>
      )}
      <div
        className="w-1/3 text-center sidebar-link trans "
        onClick={() => navigate("/discover")}
      >
        <FontAwesomeIcon icon={faList} />
        &nbsp; Discover
      </div>

      {getCurrentUser() && getCurrentUser().user.role === "admin" ? (
        <div
          className="w-1/3 text-center sidebar-link trans "
          onClick={() => navigate("/profile")}
        >
          <FontAwesomeIcon icon={faTools} />
          &nbsp; Settings
        </div>
      ) : (
        <div
          className="w-1/3 text-center sidebar-link trans "
          onClick={() => navigate("/history")}
        >
          <FontAwesomeIcon icon={faHistory} />
          &nbsp; History
        </div>
      )}
    </div>
  );
}
