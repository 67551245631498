import {
  faArrowTrendDown,
  faCandyCane,
  faCompass,
  faDownLeftAndUpRightToCenter,
  faHistory,
  faHome,
  faList,
  faMagic,
  faMessage,
  faSearchPlus,
  faTemperatureHigh,
  faTools,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getUser, getUserSessions } from "../services/user.service";
import { getSessions, setCurrentsession } from "../services/session.service";
import { updateUser } from "../services/user.service";
import LoginModal from "../modals/Login";
import RegisterModal from "../modals/Register";
import { getCurrentUser } from "../services/auth.service";
import NewsessionModal from "../modals/Newsession";

export default function Sidebar({
  currentSession,
  setCurrentSession,
  setShowNewSession,
  setShowLogin,
  setShowRegister,
  setShowLearnMore,
  updateSidebar,
  setUpdateConversation,
}) {
  const navigate = useNavigate();
  const [sessions, setSessions] = useState([]);
  useEffect(() => {
    if (getCurrentUser() && getCurrentUser().user.id)
      getUserSessions(getCurrentUser().user.id).then((response) => {
        //console.log(response);
        setSessions(response.data);
      });

    //console.log(sessions);
  }, [updateSidebar]);

  return (
    <div className="fixed flex top-0 left-0 h-full w-56 py-4 bg-[#AEC6CF] text-gray-700  flex-col justify-between">
      <div className="flex flex-col flex-1 h-0">
        <div className="pl-2 text-left">
          <h1 className="my-2 pl-4 text-[26px] font-bold trans hover:text-[#1fb8cd] hover:cursor-pointer">
            <FontAwesomeIcon icon={faMagic} />
            &nbsp;Chatnet.ai<span className="hidden">{updateSidebar}</span>
          </h1>
          <div
            className={`py-1 pl-2 mr-10 mx-2 my-3 text-md bg-white border-2 hover:border-neutral-600 rounded-3xl hover:cursor-pointer text-neutral-600 trans ${
              getCurrentUser() && getCurrentUser().user.role === "admin"
                ? "hidden"
                : "block"
            }`}
            onClick={() => setShowNewSession(true)}
          >
            <FontAwesomeIcon icon={faSearchPlus} />
            &nbsp; &nbsp; New Session
          </div>
          {/* Users */}
          <div
            className={` trans ${
              getCurrentUser() && getCurrentUser().user.role === "admin"
                ? "block mt-12 text-xl"
                : "hidden text-base"
            } py-1 mb-1 px-4 font-bold  hover:text-neutral-900 cursor-pointer hover:bg-[rgb(232,232,229)] hover:rounded-md`}
            onClick={() => navigate("/")}
          >
            <FontAwesomeIcon icon={faUsers} />
            &nbsp; Users
          </div>

          {/* Home */}
          <div
            className={`sidebar-link trans ${
              getCurrentUser() && getCurrentUser().user.role === "admin"
                ? "hidden"
                : "block"
            }`}
            onClick={() => navigate("/")}
          >
            <FontAwesomeIcon icon={faHome} />
            &nbsp; Home
          </div>

          {/* Discover */}
          <div
            className={`sidebar-link trans ${
              getCurrentUser() && getCurrentUser().user.role === "admin"
                ? "hidden"
                : "block"
            }`}
            onClick={() => navigate("/discover")}
          >
            <FontAwesomeIcon icon={faCompass} />
            &nbsp; Discover
          </div>

          {/* History */}
          <div
            className={`sidebar-link trans ${
              getCurrentUser() && getCurrentUser().user.role === "admin"
                ? "hidden"
                : "block"
            }`}
            onClick={() => navigate("/history")}
          >
            <FontAwesomeIcon icon={faHistory} />
            &nbsp; History
          </div>
        </div>
        <div
          className={` overflow-y-auto  px-3 ml-8 text-xs border-l-2 ${
            getCurrentUser() ? "block" : "hidden"
          }`}
        >
          {sessions
            //
            .filter((session) => session.title !== "@@@@@")
            .map((session, index) => (
              <div
                key={session.id}
                className={`hover:cursor-pointer hover:text-neutral-900 hover:bg-[rgb(232,232,229)] rounded-md mb-1 p-1 trans ${
                  currentSession === session.id && "bg-[rgb(232,232,229)]"
                }`}
                onClick={async () => {
                  setCurrentSession(session.id);
                  navigate("/conversation");
                  // await updateUser(getCurrentUser().user.id, {
                  //   currentSessionId: session.id,
                  // });

                  // setUpdateConversation(Math.random());

                  // window.location.reload();
                }}
              >
                {session.title}
              </div>
            ))}
        </div>
      </div>

      <div className="bottom-0 left-0 px-4">
        <div className="p-1 mb-2 text-sm">
          <p className="font-bold">Try Pro</p>
          <p>Upgrade for image upload, smarter AI, and more Copilot.</p>
          <button
            className="bg-[#91B2BE] px-2 py-1 rounded-md mt-1 hover:bg-[#8aafbc] active:bg-[#92bac9]"
            onClick={() => setShowLearnMore(true)}
          >
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="far"
              data-icon="arrow-up-right"
              className="svg-inline--fa fa-arrow-up-right fa-fw fa-1x "
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 384 512"
            >
              <path
                fill="currentColor"
                d="M328 96c13.3 0 24 10.7 24 24V360c0 13.3-10.7 24-24 24s-24-10.7-24-24V177.9L73 409c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l231-231H88c-13.3 0-24-10.7-24-24s10.7-24 24-24H328z"
              ></path>
            </svg>
            &nbsp; Learn more
          </button>
        </div>
        <hr />
        {getCurrentUser() === null ? (
          <>
            <button
              className="px-6 py-2 my-2 w-full font-bold text-white bg-[#20808D] rounded-full shadow outline-none active:bg-[#5a9ba3] hover:shadow-lg focus:outline-none"
              type="button"
              onClick={() => setShowLogin(true)}
            >
              Log in
            </button>
            <button
              className="px-6 py-2 my-2 w-full font-bold bg-white text-[#20808D] rounded-full shadow outline-none active:bg-[#5a9ba3] hover:shadow-lg focus:outline-none"
              type="button"
              onClick={() => setShowRegister(true)}
            >
              Register
            </button>
          </>
        ) : (
          <div
            className="flex items-center justify-between my-2 hover:bg-[rgb(232,232,229)] rounded-full hover:cursor-pointer trans"
            onClick={() => navigate("/profile")}
          >
            <div className="flex items-center">
              <img
                src="https://www.shareicon.net/data/128x128/2017/01/06/868320_people_512x512.png"
                alt="avatar"
                className="size-8 rounded-[50%]"
              />
              <p className="ml-2 text-sm">{getCurrentUser().user.name}</p>
            </div>
            <FontAwesomeIcon icon={faTools} className="mr-2" />
          </div>
        )}
        <hr />
      </div>
    </div>
  );
}
