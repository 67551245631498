import { faClose, faGift } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { sendVerifyEmail } from "../services/user.service";
import { getCurrentUser, login } from "../services/auth.service";
import { useNavigate } from "react-router-dom";
import { getUser } from "../services/user.service";

const SuccessModal = () => {
  const [showModal, setShowModal] = useState(true);
  const [method, setMethod] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    if (getCurrentUser())
      getUser(getCurrentUser().user.id).then((res) => {
        const store = {
          tokens: getCurrentUser().tokens,
          user: res.data,
        };
        localStorage.setItem("user", JSON.stringify(store));
        localStorage.removeItem("conversation");
      });
  }, []);
  const handleClose = () => {
    navigate("/");
  };
  return (
    <>
      {showModal ? (
        <>
          <div className="fixed inset-0 z-40 flex items-center justify-center overflow-x-hidden overflow-y-auto bg-[#303030A0] outline-none focus:outline-none">
            <div className="relative z-50 w-5/6 max-w-3xl mx-auto my-6 sm:w-4/5 lg:w-1/2 md:w-2/3">
              <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                <div className="flex items-start justify-between p-5 border-b border-gray-300 border-solid rounded-t ">
                  <h3 className="text-3xl font=semibold">Chatnet.ai</h3>
                  <button
                    className="float-right text-black bg-transparent border-0"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="block w-6 h-6 py-0 text-xl text-black rounded-full opacity-7">
                      <FontAwesomeIcon icon={faClose} />
                    </span>
                  </button>
                </div>
                <div className="relative items-center justify-center flex-auto">
                  <h1 className="text-5xl text-[#20808D] text-center my-5">
                    Subscribed successfully!
                  </h1>
                  <p className="hidden mb-6 text-xl text-center">
                    Sign in to continue
                  </p>
                  {/* <form className="w-full px-8 pt-6 pb-8 rounded shadow-md"> */}

                  <div className="hidden w-2/3 mx-auto ">
                    <button
                      className="w-full mx-auto bg-[rgb(232,232,229)] hover:bg-[rgb(172,172,170)] 
                    active:bg-[rgb(202,202,199)] py-2 rounded-full mb-4 text-center items-center"
                    >
                      <svg
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 48 48"
                        height="1.2em"
                        width="1.2em"
                        className="inline"
                      >
                        <path
                          fill="#EA4335"
                          d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                        ></path>
                        <path
                          fill="#4285F4"
                          d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                        ></path>
                        <path
                          fill="#FBBC05"
                          d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                        ></path>
                        <path
                          fill="#34A853"
                          d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                        ></path>
                        <path fill="none" d="M0 0h48v48H0z"></path>
                      </svg>
                    </button>
                  </div>

                  <hr />
                  {/* </form> */}

                  <div className="flex hidden w-full gap-5 p-10 cursor-pointer">
                    <div
                      className={`border-[1px] border-gray-400 rounded-md w-1/2 p-5 ${
                        method === 0 ? "bg-[#20808D]" : "bg-white"
                      }`}
                      onClick={() => setMethod(0)}
                    >
                      <p
                        className={` text-sm ${
                          method === 0 ? "text-white" : "text-[#20808D]"
                        }`}
                      >
                        MONTHLY
                      </p>
                      <p
                        className={`my-4 text-xl font-bold ${
                          method === 0 ? "text-white" : "text-[#20808D]"
                        }`}
                      >
                        $20
                      </p>
                      <p
                        className={`${
                          method === 0 ? "text-white" : "text-[#20808D]"
                        }`}
                      >
                        billed per month
                      </p>
                    </div>

                    <div
                      className={`border-[1px] border-gray-400 rounded-md w-1/2 p-5 ${
                        method === 1 ? "bg-[#20808D]" : "bg-white"
                      } cursor-pointer`}
                      onClick={() => setMethod(1)}
                    >
                      <div className="flex justify-between">
                        <p
                          className={` text-sm ${
                            method === 1 ? "text-white" : "text-[#20808D]"
                          }`}
                        >
                          YEARLY
                        </p>
                        <p className="text-[#1d4b51] text-sm bg-[rgb(232,232,229)] px-2 rounded-md">
                          Save $40 a year
                        </p>
                      </div>
                      <p
                        className={`my-4 text-xl font-bold ${
                          method === 1 ? "text-white" : "text-[#20808D]"
                        }`}
                      >
                        $200
                      </p>
                      <p
                        className={`${
                          method === 1 ? "text-white" : "text-[#20808D]"
                        }`}
                      >
                        billed per year
                      </p>

                      <p
                        className={`${
                          method === 1 ? "text-white" : "text-[#20808D]"
                        } text-sm mt-3`}
                      >
                        <FontAwesomeIcon icon={faGift} />
                        &nbsp;&nbsp;7 day free trial
                      </p>
                    </div>
                  </div>
                  <p className="my-4 text-2xl text-center">
                    Now, you are available to use chatnet.ai
                  </p>
                </div>
                <div className="flex items-center justify-end p-6 border-t border-solid rounded-b border-blueGray-200">
                  <button
                    className="hidden px-6 py-2 mb-1 mr-1 text-sm font-bold text-red-500 uppercase outline-none background-transparent focus:outline-none"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                  <button
                    className="px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase bg-[#20808D] rounded shadow outline-none active:bg-[#86c2ca] hover:shadow-lg focus:outline-none"
                    type="button"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default SuccessModal;
