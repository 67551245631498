import { useEffect, useRef } from "react";
import autosize from "autosize";
import {
  createSession,
  setCurrentsession,
  updateSession,
} from "../services/session.service";
import { updateUser } from "../services/user.service";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { getCurrentUser, login } from "../services/auth.service";
import {
  faArrowAltCircleRight,
  faArrowRight,
  faArrowRightFromFile,
  faArrowRightRotate,
  faArrowUpRightFromSquare,
  faArrowsLeftRight,
  faChessKnight,
  faCircleArrowRight,
  faCrown,
  faPrint,
  faSadCry,
} from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import { createConversation, getResponse } from "../services/openai.service";

export default function Home({
  setCurrentSession,
  setUpdateConversation,
  setUpdateSidebar,
  conversation,
  setConversation,
  setUpdateScrollView,
}) {
  const navigate = useNavigate();

  const [prompt, setPrompt] = useState("");
  const submitBtn = useRef(null);

  const [queryParameters] = useSearchParams();
  const params = useParams();

  const handleQuickSearch = async (e) => {
    const prom = e.target.innerHTML;

    if (!getCurrentUser()) {
      // alert("Login first!");

      createConversation(10761, prom).then(async (res) => {
        localStorage.setItem("conversation", JSON.stringify(res));
        const conversation = [];
        conversation.push(prom);
        setUpdateSidebar(Math.random());

        getResponse(prom, res.data.project_id, res.data.session_id)
          .then((source) => {
            source.addEventListener("start", (e) => {
              const conv = conversation;
              if (conv.length % 2 === 1) conv.push("");
              setConversation(conv);
            });
            source.addEventListener("progress", (e) => {
              let conv = conversation;
              if (conv.length % 2 === 1) {
                //        console.log("Why is the length of all chats odd?");
                return;
              }
              let payload = JSON.parse(e.data);
              conv[conv.length - 1] += payload["message"];
              setConversation(conv);
              setUpdateScrollView(Math.random());
            });
            source.addEventListener("finish", (e) => {
              //       console.log(e);
            });

            source.stream();
          })
          .catch(function (error) {
            console.error(error);
          });

        navigate("/conversation");
      });
    } else {
      createConversation(10761, prom).then(async (res) => {
        //  console.log("Auth > Home:", res);

        createSession({
          title: prom,
          projectId: res.data.project_id,
          convId: res.data.session_id,
          userId: getCurrentUser().user.id,
        }).then(async (res) => {
          await updateUser(getCurrentUser().user.id, {
            currentSessionId: res.id,
          });

          const conversation = [];
          conversation.push(prom);
          setCurrentSession(res.id);
          setUpdateSidebar(Math.random());
          getResponse(prom, res.projectId, res.convId)
            .then((source) => {
              source.addEventListener("start", (e) => {
                const conv = conversation;
                if (conv.length % 2 === 1) conv.push("");
                setConversation(conv);
              });
              source.addEventListener("progress", (e) => {
                let conv = conversation;
                if (conv.length % 2 === 1) {
                  //     console.log("Why is the length of all chats odd?");
                  return;
                }
                let payload = JSON.parse(e.data);

                conv[conv.length - 1] += payload["message"];
                //   console.log(conv);
                setConversation(conv);
                setUpdateScrollView(Math.random());
              });
              source.addEventListener("finish", (e) => {
                //    console.log(e);
              });

              source.stream();
            })
            .catch(function (error) {
              console.error(error);
            });

          navigate("/conversation");
        });
      });
    }
  };
  const handleInput = async (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      if (e.target.value === "") return;

      if (!getCurrentUser()) {
        // alert("Login first!");

        createConversation(10761, e.target.value).then(async (res) => {
          localStorage.setItem("conversation", JSON.stringify(res));
          const conversation = [];
          conversation.push(prompt);
          setUpdateSidebar(Math.random());

          getResponse(prompt, res.data.project_id, res.data.session_id)
            .then((source) => {
              source.addEventListener("start", (e) => {
                const conv = conversation;
                if (conv.length % 2 === 1) conv.push("");
                setConversation(conv);
              });
              source.addEventListener("progress", (e) => {
                let conv = conversation;
                if (conv.length % 2 === 1) {
                  //   console.log("Why is the length of all chats odd?");
                  return;
                }
                let payload = JSON.parse(e.data);
                conv[conv.length - 1] += payload["message"];
                console.log(conv);
                setConversation(conv);
                setUpdateScrollView(Math.random());
              });
              source.addEventListener("finish", (e) => {
                //   console.log(e);
              });

              source.stream();
            })
            .catch(function (error) {
              console.error(error);
            });

          navigate("/conversation");
        });
      } else {
        createConversation(10761, e.target.value).then(async (res) => {
          //   console.log("Auth > Home:", res);

          createSession({
            title: e.target.value,
            projectId: res.data.project_id,
            convId: res.data.session_id,
            userId: getCurrentUser().user.id,
          }).then(async (res) => {
            await updateUser(getCurrentUser().user.id, {
              currentSessionId: res.id,
            });

            const conversation = [];
            conversation.push(prompt);
            setCurrentSession(res.id);
            setUpdateSidebar(Math.random());
            getResponse(prompt, res.projectId, res.convId)
              .then((source) => {
                source.addEventListener("start", (e) => {
                  const conv = conversation;
                  if (conv.length % 2 === 1) conv.push("");
                  setConversation(conv);
                });
                source.addEventListener("progress", (e) => {
                  let conv = conversation;
                  if (conv.length % 2 === 1) {
                    //    console.log("Why is the length of all chats odd?");
                    return;
                  }
                  let payload = JSON.parse(e.data);
                  conv[conv.length - 1] += payload["message"];
                  setConversation(conv);
                  setUpdateScrollView(Math.random());
                });
                source.addEventListener("finish", (e) => {
                  //      console.log(e);
                });

                source.stream();
              })
              .catch(function (error) {
                console.error(error);
              });

            navigate("/conversation");
          });
        });
      }
    }
  };
  useEffect(() => {
    //autosize(document.querySelectorAll("textarea"));
    //console.log(document.querySelectorAll("textarea"));
    //console.log(params);
    const email = queryParameters.get("email");
    const password = queryParameters.get("password");
    const userId = queryParameters.get("id");
    const name = queryParameters.get("name");
    //console.log(email);
    //console.log(password);
    //console.log(userId);
    if (email !== null && password !== null && userId !== null) {
      //console.log(email);
      //console.log(password);
      //console.log(userId);
      // login(email, password).then(() => {
      //   window.location.reload();
      // });
      const user = {
        id: userId,
        name: name,
        password: password,
        email: email,
        isEmailVerified: true,
      };

      login(email, password).then(() => {
        navigate("/");
      });

      //ocalStorage.setItem("user", JSON.stringify({ user }));
      //navigate("/");
    }
  }, []);
  useEffect(() => {
    if (prompt === "") submitBtn.current.style.color = "#999";
    else submitBtn.current.style.color = "#20808D";
  }, [prompt]);
  return (
    <div className="flex flex-col items-center justify-center max-w-5xl m-2 mx-auto rounded-lg ">
      <h1 className="text-4xl text-[#13343B] mt-[10%] font-semibold">
        Sprintray Support Chat
      </h1>
      <div className="relative z-0 flex justify-between w-3/4 max-w-5xl mt-8 mb-2">
        <textarea
          className="relative w-full p-4 border-[#9a9e9f] border-2 rounded-lg max-h-80"
          rows={3}
          placeholder="Ask anything..."
          onKeyDown={handleInput}
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
        ></textarea>

        <FontAwesomeIcon
          icon={faCircleArrowRight}
          className={`absolute my-2 ml-3  size-7 bottom-1 right-3 text-[#1fb8cd] hover:text-[#9ddae2] hover:cursor-pointer 
          aria-disabled:text-gray-700`}
          //onClick={handleSubmit}
          ref={submitBtn}
        />
      </div>

      <div className="gap-4 my-1 text-xs text-center">
        <span
          className=" faq-button whitespace-nowrap"
          onClick={handleQuickSearch}
        >
          Tell me why prints fail?
        </span>
        <span
          className="py-3 my-5 faq-button whitespace-nowrap"
          onClick={handleQuickSearch}
        >
          How do I print a nightguard?
        </span>
        <span
          className="py-3 my-5 faq-button whitespace-nowrap"
          onClick={handleQuickSearch}
        >
          Can Sprintray 95s print crowns?
        </span>
      </div>
    </div>
  );
}
