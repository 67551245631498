import {
  faCircle,
  faCircleArrowUp,
  faListUl,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import {
  getSession,
  updateSession,
  createSession,
} from "../services/session.service";
import { getMessages, getResponse } from "../services/openai.service";
import { useNavigate } from "react-router-dom";
import { updateUser } from "../services/user.service";
import { getDiscover, getDiscovers } from "../services/discover.service";
import { getCurrentUser } from "../services/auth.service";
import MarkdownRenderer from "../components/MarkdownRenderer";

export default function Conversation({
  currentSession,
  updateConversation,
  setUpdateConversation,
  setUpdateSidebar,
  conversation,
  setConversation,
  updateScrollView,
  setUpdateScrollView,
}) {
  const chat = useRef(null);
  const [prompt, setPrompt] = useState("");
  const submitBtn = useRef(null);
  const navigate = useNavigate();
  const [discover, setDiscover] = useState({});

  const [sessionType, setSessionType] = useState(0);

  useEffect(() => {
    if (prompt === "") submitBtn.current.style.color = "#999";
    else submitBtn.current.style.color = "#20808D";
  }, [prompt]);

  useEffect(() => {
    chat?.current?.scrollIntoView({ behavior: "smooth", block: "end" });
  }, [updateScrollView]);

  useEffect(() => {
    // console.log("AAAAAAAAAAAAAAAAAA");
    if (!getCurrentUser()) {
      let conv_data = localStorage.getItem("conversation");
      if (conv_data) conv_data = JSON.parse(conv_data);
      if (conv_data) {
        if (
          conv_data.data.project.id != 10761 ||
          (conv_data.data.project.id == 10761 &&
            conv_data.data.name === "Sprintray")
        ) {
          getDiscover(conv_data.data.project.id).then((res) => {
            console.log(res);
            setDiscover({
              title: res.title,
              content: res.content,
              image: res.image,
            });
          });
        } else setDiscover({});

        getMessages(conv_data.data.project_id, conv_data.data.session_id).then(
          (res) => {
            //console.log(res);
            const conv = [];
            for (let chat of res.data.messages.data) {
              conv.push(chat.user_query);
              conv.push(chat.openai_response);
            }

            if (
              res.data.conversation.project_id !== 10761 ||
              res.data.conversation.name === "Sprintray"
            ) {
            } else if (res.data.messages.data.length === 0) {
              conv.push(res.data.conversation.name);
            }
            setConversation(conv);
            setUpdateScrollView(Math.random());
          }
        );
      }
    }
    if (!currentSession) return;
    getSession(currentSession).then((response) => {
      getDiscover(response.projectId).then((res) => {
        if (
          response.projectId != 10761 ||
          (response.projectId == 10761 && response.title === "Sprintray")
        ) {
          console.log(res);
          setDiscover({
            title: res.title,
            content: res.content,
            image: res.image,
          });
        } else setDiscover({});
      });
      getMessages(response.projectId, response.convId).then((res) => {
        const conv = [];
        for (let chat of res.data.messages.data) {
          conv.push(chat.user_query);
          conv.push(chat.openai_response);
        }
        if (
          res.data.conversation.project_id !== 10761 ||
          res.data.conversation.name === "Sprintray"
        ) {
        } else if (res.data.messages.data.length === 0) {
          conv.push(res.data.conversation.name);
        }
        setConversation(conv);
        //  console.log(conv);
        setUpdateScrollView(Math.random());
      });
    });
  }, [currentSession, updateConversation]);

  const handleRefresh = (e) => {
    createSession({
      title: "Anonymouse",
      chat: [],
    }).then(async (res) => {
      currentSession = res.id;

      await updateUser(getCurrentUser().user.id, {
        currentSession: currentSession,
      });
    });
    setConversation([]);
    window.location.reload();
  };
  const handleSubmit = () => {
    if (prompt.length === 0 && conversation.length % 2 === 1) return;
    const conv = conversation;
    conv.push(prompt);
    setConversation(conv);
    setUpdateScrollView(Math.random());
    getSession(currentSession).then((res) => {
      getResponse(prompt, res.projectId, res.convId)
        .then((source) => {
          source.addEventListener("start", (e) => {
            const conv = conversation;
            if (conv.length % 2 === 1) conv.push("");
            setConversation(conv);
          });
          source.addEventListener("progress", (e) => {
            let conv = conversation;
            if (conv.length % 2 === 1) {
              //    console.log("Why is the length of all chats odd?");
              return;
            }
            let payload = JSON.parse(e.data);
            conv[conv.length - 1] += payload["message"];
            setConversation(conv);
            setUpdateScrollView(Math.random());
          });
          source.addEventListener("finish", (e) => {
            //    console.log(e);
          });

          source.stream();
        })
        .catch(function (error) {
          console.error(error);
        });
    });
    setPrompt("");
  };
  const handlePrompt = (e) => {
    if (e.key === "Enter" && prompt.length > 0) {
      if (conversation.length % 2 === 1) {
        e.preventDefault();
        return;
      }

      if (!getCurrentUser()) {
        let conv_data = localStorage.getItem("conversation");
        if (conv_data) conv_data = JSON.parse(conv_data);
        if (conv_data) {
          const conv = conversation;
          conv.push(prompt);
          setConversation(conv);
          setUpdateScrollView(Math.random());

          getResponse(
            prompt,
            conv_data.data.project_id,
            conv_data.data.session_id
          )
            .then((source) => {
              source.addEventListener("start", (e) => {
                const conv = conversation;
                if (conv.length % 2 === 1) conv.push("");
                setConversation(conv);
              });
              source.addEventListener("progress", (e) => {
                let conv = conversation;
                if (conv.length % 2 === 1) {
                  //    console.log("Why is the length of all chats odd?");
                  return;
                }
                let payload = JSON.parse(e.data);
                conv[conv.length - 1] += payload["message"];
                setConversation(conv);
                setUpdateScrollView(Math.random());
              });
              source.addEventListener("finish", (e) => {
                //     console.log(e);
              });

              source.stream();
              // setConversation([...conv, res.data.openai_response]);
              // setUpdateScrollView(Math.random());
            })
            .catch(function (error) {
              console.error(error);
            });

          setPrompt("");
        }
      } else {
        if (conversation.length === 0) {
          updateSession(currentSession, {
            title: prompt,
          }).then(() => setUpdateSidebar(Math.random()));
        }

        const conv = conversation;
        conv.push(prompt);
        setConversation(conv);
        setUpdateScrollView(Math.random());
        getSession(currentSession).then((res) => {
          getResponse(prompt, res.projectId, res.convId)
            .then((source) => {
              source.addEventListener("start", (e) => {
                const conv = conversation;
                if (conv.length % 2 === 1) conv.push("");
                setConversation(conv);
              });
              source.addEventListener("progress", (e) => {
                let conv = conversation;
                if (conv.length % 2 === 1) {
                  //     console.log("Why is the length of all chats odd?");
                  return;
                }
                let payload = JSON.parse(e.data);
                conv[conv.length - 1] += payload["message"];
                setConversation(conv);
                setUpdateScrollView(Math.random());
              });
              source.addEventListener("finish", (e) => {
                //     console.log(e);
              });

              source.stream();
              // setConversation([...conv, res.data.openai_response]);
              // setUpdateScrollView(Math.random());
            })
            .catch(function (error) {
              console.error(error);
            });
        });

        setPrompt("");
      }
    }
  };

  return (
    <div className="relative h-full max-w-5xl pt-10 mx-auto">
      <div
        className="pb-40 overflow-y-auto md:pb-28 text-neutral-700"
        ref={chat}
      >
        {discover && discover.title && (
          <div
            className={
              conversation.length > 0 ? "bg-[rgb(232,232,229)] rounded-xl" : ""
            }
          >
            <div className="flex gap-5">
              <div className="w-full p-3 text-2xl font-bold">
                {discover.title}
              </div>
            </div>
            <div className={conversation.length === 0 ? "block" : "hidden"}>
              <div className="pl-5 text-2xl">
                <FontAwesomeIcon icon={faListUl} className="size-5" />
                &nbsp;&nbsp;Answer
              </div>
              <div className="flex justify-between w-full gap-3 p-5 whitespace-pre-wrap">
                <div>{discover.content}</div>
                <img
                  src={`${process.env.REACT_APP_API_ROOT}/v1/result/${discover.image}`}
                  alt={discover.title}
                  className="object-contain rounded-lg shadow-lg size-20"
                />
              </div>
              <hr />
            </div>
          </div>
        )}
        {conversation.map((conv, index) => (
          <div key={index}>
            {index % 2 === 0 ? (
              <div className="flex gap-5">
                {/* <div className="w-[5%] pt-2 text-center text-white bg-gray-500 rounded-lg">
                  <FontAwesomeIcon icon={faUser} className="size-8" />
                </div> */}
                <div className="w-full p-5 text-3xl font-semibold ">
                  {conv}
                  <span className="hidden">{updateConversation}</span>
                </div>
              </div>
            ) : (
              <div>
                <div className="pl-5 text-xl font-medium">
                  <FontAwesomeIcon icon={faListUl} className="size-5" />
                  &nbsp;&nbsp;Answer
                </div>
                <div className="w-full p-5">
                  <MarkdownRenderer markdownText={conv} />
                </div>
                <hr />
              </div>
            )}
          </div>
        ))}
        {conversation.length % 2 === 1 && (
          <div>
            <div className="pl-5 text-xl">
              <FontAwesomeIcon icon={faListUl} className="size-5" />
              &nbsp;&nbsp;Answer
            </div>
            <div className="w-full p-5 animate-pulse">
              <FontAwesomeIcon icon={faCircle} className="size-5" />
            </div>
          </div>
        )}
      </div>
      <div className="fixed bottom-0 flex flex-col w-full h-40 md:h-20">
        <div className="relative flex max-w-5xl mr-8 h-14 md:mb-5">
          <input
            className="w-full pl-16 text-lg border-2 border-gray-300 rounded-3xl ring-8 ring-[rgb(232,232,229)] items-center trans"
            onKeyDown={handlePrompt}
            placeholder="Ask follow up..."
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
          />

          <FontAwesomeIcon
            icon={faCircleArrowUp}
            className={`absolute my-3 ml-5 size-8 right-5 hover:text-[#9ddae2] hover:cursor-pointer 
          `}
            onClick={handleSubmit}
            ref={submitBtn}
          />

          <FontAwesomeIcon
            icon={faPlusCircle}
            className="absolute my-4 ml-5  size-6  text-[#d0d0d0] hover:text-[#505252] hover:cursor-pointer"
          />
        </div>
      </div>
    </div>
  );
}
