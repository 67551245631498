import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import axios from "axios";
import { sendVerifyEmail } from "../services/user.service";
import { getCurrentUser, login, register } from "../services/auth.service";
import { useNavigate } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";
import { useEffect } from "react";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";

const LoginModal = ({ showModal, setShowModal, title }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrormessage] = useState("");
  const navigate = useNavigate();
  const [profile, setProfile] = useState([]);
  const [user, setUser] = useState(null);

  const loginGoogle = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });

  const handleLogin = async () => {
    login(email, password).then(
      () => {
        window.location.reload();
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setErrormessage(resMessage);
      }
    );
  };
  const handleVerifyClick = async () => {
    sendVerifyEmail(email)
      .then((res) => {
        //console.log("Successed to send email:", res);
      })
      .catch((error) => {
        console.error("Failed to send email:", error);
      });
  };

  useEffect(() => {
    //console.log(user);
    if (user) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          setProfile(res.data);
          //console.log(res.data);
          const email = res.data.email;
          const pass = "google1234!@";
          const name = res.data.name;
          login(email, pass).then(
            (response) => {
              //console.log(">>> Login: ", response);
              //ocalStorage.setItem("user", JSON.stringify(response));
              window.location.reload();
            },
            (error) => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();

              if (resMessage === "Incorrect email or password") {
                register(name, email, pass).then((response) => {
                  //console.log(">>> Regster: ", response);
                  localStorage.setItem("user", JSON.stringify(response.data));
                  localStorage.removeItem("conversation");
                  document.location.reload();
                });
              }
              //console.log(resMessage);
            }
          );
        })
        .catch((err) => console.log(err));
    }
  }, [user]);

  return (
    <>
      {showModal ? (
        <>
          <div className="fixed inset-0 z-40 flex items-center justify-center overflow-x-hidden overflow-y-auto bg-[#303030A0] outline-none focus:outline-none">
            <div className="relative z-50 w-5/6 max-w-md mx-auto my-1 sm:w-4/5 lg:w-1/2 md:w-2/3">
              <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                <div className="flex items-start justify-between px-5 py-2 border-b border-gray-300 border-solid ">
                  <h3 className="text-2xl">Chatnet.ai</h3>
                  <button
                    className="float-right text-black bg-transparent border-0"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="block w-6 h-6 py-0 text-xl text-black rounded-full opacity-7">
                      <FontAwesomeIcon icon={faClose} />
                    </span>
                  </button>
                </div>
                <div className="relative items-center justify-center flex-auto my-1">
                  <h1 className="text-4xl font-bold text-[#20808D] text-center my-4">
                    {title}
                  </h1>
                  {title === "Welcome" && (
                    <p className="mb-3 text-xl text-center">
                      Sign in to continue
                    </p>
                  )}
                  {/* <form className="w-full px-8 pt-6 pb-8 rounded shadow-md"> */}

                  <div className="w-2/3 mx-auto ">
                    <button
                      className="w-full mx-auto bg-[rgb(232,232,229)] hover:bg-[rgb(172,172,170)] 
                    active:bg-[rgb(202,202,199)] py-2 rounded-full mb-4 text-center items-center"
                      onClick={loginGoogle}
                    >
                      <svg
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 48 48"
                        height="1.2em"
                        width="1.2em"
                        className="inline"
                      >
                        <path
                          fill="#EA4335"
                          d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                        ></path>
                        <path
                          fill="#4285F4"
                          d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                        ></path>
                        <path
                          fill="#FBBC05"
                          d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                        ></path>
                        <path
                          fill="#34A853"
                          d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                        ></path>
                        <path fill="none" d="M0 0h48v48H0z"></path>
                      </svg>
                      <span
                        className="font-bold"

                        //href={`${process.env.REACT_APP_API_ROOT}/v1/auth/google`}
                        //href="https://test.chatnet.ai/v1/auth/google"
                      >
                        &nbsp;&nbsp;Continue with Google
                      </span>
                    </button>
                  </div>

                  <div className="w-2/3 mx-auto ">
                    <input
                      className="w-full px-3 py-2 mx-auto my-1 text-black border rounded shadow appearance-none rounded-3xl"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="henry@example.com"
                    />

                    <input
                      className="w-full px-3 py-2 mx-auto mt-1 mb-4 text-black border rounded shadow appearance-none rounded-3xl"
                      value={password}
                      type="password"
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="••••••••"
                    />
                    <div
                      className={
                        errorMessage.length === 0
                          ? "hidden error-message"
                          : "block error-message"
                      }
                    >
                      {errorMessage}
                    </div>
                  </div>
                  {/* </form> */}
                </div>
                <div className="flex items-center justify-end p-6 border-t border-solid rounded-b border-blueGray-200">
                  <button
                    className="hidden px-6 py-2 mb-1 mr-1 text-sm font-bold text-red-500 uppercase outline-none background-transparent focus:outline-none"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                  <button
                    className="px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase bg-[#20808D] rounded shadow outline-none active:bg-[#86c2ca] hover:shadow-lg focus:outline-none"
                    type="button"
                    onClick={() => {
                      handleLogin();
                    }}
                  >
                    Log in
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default LoginModal;
