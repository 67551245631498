import Sidebar from "./components/Sidebar";
import SuccessModal from "./modals/Sucess";
import FailureModal from "./modals/Failure";
import Conversation from "./pages/Conversation";
import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import { getUser } from "./services/user.service";
import Discover from "./pages/Discover";
import { getCurrentUser } from "./services/auth.service";
import Profile from "./pages/Profile";
import UsersPage from "./pages/Users";
import NewsessionModal from "./modals/Newsession";
import LoginModal from "./modals/Login";
import RegisterModal from "./modals/Register";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Footbar from "./components/Footbar";
import LearnMoreDialog from "./modals/Learnmore";
import PaymentModal from "./modals/Payment";
import History from "./pages/History";
function App() {
  //console.log(">>> ENV: ", process.env.REACT_APP_API_ROOT);

  const [currentSession, setCurrentSession] = useState(() => {
    //console.log(getCurrentUser());
    if (getCurrentUser() && getCurrentUser().user)
      getUser(getCurrentUser().user.id).then((res) => {
        setCurrentSession(res.data.currentSessionId);
        //console.log(res.data.currentSessionId);
      });
    return 0;
  });
  const [showNewSession, setShowNewSession] = useState(false);
  const [showLearnMore, setShowLearnMore] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const [updateConversation, setUpdateConversation] = useState(0);
  const [updateSidebar, setUpdateSidebar] = useState(0);
  const [conversation, setConversation] = useState([]);
  const [updateScrollView, setUpdateScrollView] = useState(0);
  return (
    <div className="flex App">
      <div className="flex w-full h-full">
        <NewsessionModal
          showNewSession={showNewSession}
          setShowNewSession={setShowNewSession}
          setCurrentSession={setCurrentSession}
          setUpdateSidebar={setUpdateSidebar}
          setUpdateConversation={setUpdateConversation}
          conversation={conversation}
          setConversation={setConversation}
          setUpdateScrollView={setUpdateScrollView}
        />

        <LoginModal
          showModal={showLogin}
          setShowModal={setShowLogin}
          title="Welcome"
        />
        <PaymentModal showModal={showPayment} setShowModal={setShowPayment} />
        <RegisterModal
          showModal={showRegister}
          setShowModal={setShowRegister}
        />

        <LearnMoreDialog
          showModal={showLearnMore}
          setShowModal={setShowLearnMore}
        />

        <div className={`z-0 hidden max-h-max md:block`}>
          <Sidebar
            currentSession={currentSession}
            setCurrentSession={setCurrentSession}
            setShowNewSession={setShowNewSession}
            setShowLogin={setShowLogin}
            setShowRegister={setShowRegister}
            updateSidebar={updateSidebar}
            setUpdateConversation={setUpdateConversation}
            setShowLearnMore={setShowLearnMore}
          />
        </div>
        <div className="md:pl-60 w-full min-h-[100vh] p-5 m-0 bg-white">
          <Routes>
            <Route
              path="/"
              element={
                getCurrentUser() &&
                getCurrentUser().user.role &&
                getCurrentUser().user.role === "admin" ? (
                  <UsersPage />
                ) : (
                  <Home
                    setCurrentSession={setCurrentSession}
                    setUpdateSidebar={setUpdateSidebar}
                    setUpdateConversation={setUpdateConversation}
                    conversation={conversation}
                    setConversation={setConversation}
                    setUpdateScrollView={setUpdateScrollView}
                  />
                )
              }
            />
            <Route path="/success" element={<SuccessModal />} />
            <Route path="/failure" element={<FailureModal />} />
            <Route
              path="/history"
              element={
                <History
                  setCurrentSession={setCurrentSession}
                  setUpdateConversation={setUpdateConversation}
                  setUpdateSidebar={setUpdateSidebar}
                />
              }
            />
            <Route
              path="/:userId"
              element={
                <Home
                  setCurrentSession={setCurrentSession}
                  setUpdateSidebar={setUpdateSidebar}
                  setUpdateConversation={setUpdateConversation}
                  conversation={conversation}
                  setConversation={setConversation}
                  setUpdateScrollView={setUpdateScrollView}
                  updateScrollView={updateConversation}
                />
              }
            />

            <Route
              path="/profile"
              element={
                <Profile
                  showPayment={showPayment}
                  setShowPayment={setShowPayment}
                />
              }
            />

            <Route
              path="/conversation"
              element={
                <Conversation
                  currentSession={currentSession}
                  updateConversation={updateConversation}
                  setUpdateConversation={setUpdateConversation}
                  setUpdateSidebar={setUpdateSidebar}
                  conversation={conversation}
                  setConversation={setConversation}
                  updateScrollView={updateScrollView}
                  setUpdateScrollView={setUpdateScrollView}
                />
              }
            />

            <Route
              path="/discover"
              element={
                getCurrentUser() && getCurrentUser().user.role === "admin" ? (
                  <div></div>
                ) : (
                  <Discover
                    setCurrentSession={setCurrentSession}
                    setUpdateSidebar={setUpdateSidebar}
                    setUpdateConversation={setUpdateConversation}
                  />
                )
              }
            />
          </Routes>

          <Footbar />
        </div>
      </div>
    </div>
  );
}

export default App;
