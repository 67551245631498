import axios from "axios";

//const USER_URL = "https://test.chatnet.ai/v1/users/";
const USER_URL = `${process.env.REACT_APP_API_ROOT}/v1/users/`;

export const updateUser = async (id, data) => {
  try {
    const response = await axios.patch(USER_URL + id + "/", data);
    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};

export const getUser = async (id) => {
  return axios.get(USER_URL + id + "/");
};

export const sendVerifyEmail = async (email) => {
  return axios.post(USER_URL + "send-verification-email/", {
    email,
  });
};

export const getUserSessions = async (id) => {
  return axios.get(USER_URL + id + "/sessions/");
};

export const remove = (id) => {
  return axios.delete(USER_URL + id);
};
