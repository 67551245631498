import { FaEdit, FaHeart, FaRemoveFormat, FaTrash } from "react-icons/fa";
import { remove } from "../services/auth.service";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import UpdateProjectModal from "../modals/UpdateProject";
export function DiscoverTable({ discoverData, projectData, toggleDiscover }) {
  const [filter, setFilter] = useState("!@#$%^&*(");
  const [search, setSearch] = useState("");
  const [used, setUsed] = useState([]);
  const searchTitle = (e) => {
    if (e.key === "Enter") {
      let s = e.target.value.toLowerCase();
      if (s === "") s = "!@#$%^&*(";
      setFilter(s);
    }
  };
  return (
    <div>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg text-[#64645F] text-xl">
        <div className="flex flex-col justify-between">
          <div className="flex items-center justify-between px-5 py-4 md:flex-row md:space-y-0">
            <div className="text-xl font-bold text-black">
              Public: {discoverData.length}
              {/* / Total:
              {
                projectData.filter((project) =>
                  project.project_name.includes(filter)
                ).length
              } */}
            </div>
          </div>
        </div>
        <table className="w-full text-lg text-left text-gray-500">
          <thead className="text-lg text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col w-1/12" className="p-4">
                Visible
              </th>
              <th scope="col" className="px-6 py-3 w-1/8">
                NO
              </th>
              <th scope="col" className="px-6 py-3 w-1/8">
                Title
              </th>
              <th scope="col" className="w-full px-6 py-3">
                Content
              </th>
              <th scope="col" className="w-1/12 px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {projectData
              // .filter((project) => project.project_name.includes(filter))
              .filter(
                (project) =>
                  discoverData.filter(
                    (discover) => discover.projectId === project.id
                  ).length > 0
              )
              .map((project, index) => (
                <tr
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                  key={index}
                >
                  <td className="w-4 p-4">
                    <img
                      src={`${process.env.REACT_APP_API_ROOT}/v1/result/${
                        discoverData.filter(
                          (discover) => discover.projectId === project.id
                        )[0].image
                      }`}
                      alt="asdf"
                      className="rounded-lg"
                    />
                  </td>
                  <th className="px-6 py-4">{project.id}</th>
                  <td className="px-6 py-4">{project.project_name}</td>
                  <td className="flex-wrap px-6 py-4 break-words whitespace-pre-wrap text-wrap">
                    {discoverData.filter(
                      (discover) => discover.projectId === project.id
                    ).length > 0 &&
                      discoverData.filter(
                        (discover) => discover.projectId === project.id
                      )[0].content}
                  </td>
                  <td className="flex px-6 py-4">
                    <UpdateProjectModal
                      id={project.id}
                      project={project.project_name}
                      toggleDiscover={toggleDiscover}
                      initContent={
                        discoverData.filter(
                          (discover) => discover.projectId === project.id
                        )[0].content
                      }
                      defaultImage={`${
                        process.env.REACT_APP_API_ROOT
                      }/v1/result/${
                        discoverData.filter(
                          (discover) => discover.projectId === project.id
                        )[0].image
                      }`}
                      type={0}
                    />
                    &nbsp;&nbsp;
                    <div>
                      <FontAwesomeIcon
                        icon={faTrash}
                        className="hover:cursor-pointer hover:text-red-600 size-6"
                        onClick={() =>
                          toggleDiscover(project.id, false, "", null)
                        }
                      />
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <div className="relative my-3 ml-5 ">
          <div className="absolute inset-y-0 flex items-center pointer-events-none start-0 ps-3">
            <svg
              className="w-4 h-4 text-gray-500 dark:text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
              />
            </svg>
          </div>
          <input
            type="text"
            id="table-search-users"
            className="py-2 text-sm text-gray-900 border border-blue-300 rounded-lg ps-10 w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 "
            placeholder="Search to import new chatbot."
            onKeyDown={searchTitle}
            onChange={(e) => setSearch(e.target.value)}
          />

          <button
            className="ml-4 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            onClick={() => {
              let s = search.toLowerCase();
              if (s === "") s = "!@#$%^&*(";
              setFilter(s);
            }}
          >
            Search
          </button>
        </div>

        <div className="flex flex-col justify-between">
          <div className="flex items-center justify-between px-5 py-4 md:flex-row md:space-y-0">
            <div className="text-xl font-bold text-black">
              Result:{" "}
              {
                projectData.filter(
                  (project) =>
                    (project.project_name.toLowerCase().includes(filter) ||
                      String(project.id).toLowerCase().includes(filter)) &&
                    discoverData.filter(
                      (discover) => discover.projectId === project.id
                    ).length === 0
                ).length
              }
              {/* / Total:
              {
                projectData.filter((project) =>
                  project.project_name.includes(filter)
                ).length
              } */}
            </div>
          </div>
        </div>
        <table className="w-full text-lg text-left text-gray-500">
          <thead className="text-lg text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col w-1/12" className="p-4">
                Visible
              </th>
              <th scope="col" className="px-6 py-3 w-1/8">
                NO
              </th>
              <th scope="col" className="px-6 py-3 w-1/8">
                Title
              </th>
              <th scope="col" className="w-full px-6 py-3">
                Content
              </th>
              <th scope="col" className="w-1/12 px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {projectData
              .filter(
                (project) =>
                  (project.project_name.toLowerCase().includes(filter) ||
                    String(project.id).toLowerCase().includes(filter)) &&
                  discoverData.filter(
                    (discover) => discover.projectId === project.id
                  ).length === 0
              )
              .map((project, index) => (
                <tr
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                  key={index}
                >
                  <td className="w-4 p-4">
                    <div
                      className={`${
                        discoverData.filter(
                          (discover) => discover.projectId === project.id
                        ).length > 0
                          ? "bg-[#52de3f]"
                          : "bg-[#2f2f2f]"
                      } size-5 rounded-full`}
                    ></div>
                  </td>
                  <th className="px-6 py-4">{project.id}</th>
                  <td className="px-6 py-4">{project.project_name}</td>
                  <td className="flex-wrap px-6 py-4 break-words whitespace-pre-wrap text-wrap">
                    {discoverData.filter(
                      (discover) => discover.projectId === project.id
                    ).length > 0 &&
                      discoverData.filter(
                        (discover) => discover.projectId === project.id
                      )[0].content}
                  </td>
                  <td className="flex px-6 py-4">
                    <UpdateProjectModal
                      id={project.id}
                      project={project.project_name}
                      toggleDiscover={toggleDiscover}
                      type={1}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
