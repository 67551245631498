import axios from "axios";
import { SSE } from "sse";

export const getProjects = async (currentPage = 1, filter = {}) => {
  const openai_url = `https://app.customgpt.ai/api/v1/projects?page=${currentPage}`;
  const headers = {
    authorization: "Bearer 3054|RnHVbOPRg3IZR5EWnFotdraPXulztrXzjI4RT8sv",
    accept: "application/json",
    "content-type": "application/json",
  };
  const data = {};
  try {
    const response = await axios.get(openai_url, {
      headers,
    });

    return response.data;
  } catch (error) {
    // Handle errors appropriately (e.g., logging, displaying error messages)
    console.error("Error fetching messages:", error);

    throw error;
  }
};

export const getMessages = async (projectId, convId) => {
  const openai_url = `https://app.customgpt.ai/api/v1/projects/${projectId}/conversations/${convId}/messages?order=asc`;
  const headers = {
    authorization: "Bearer 3054|RnHVbOPRg3IZR5EWnFotdraPXulztrXzjI4RT8sv",
    accept: "application/json",
    "content-type": "application/json",
  };
  const data = {};
  try {
    const response = await axios.get(openai_url, {
      headers,
    });

    return response.data;
  } catch (error) {
    // Handle errors appropriately (e.g., logging, displaying error messages)
    console.error("Error fetching messages:", error);

    throw error;
  }
};

export const createConversation = async (projectId, title) => {
  const openai_url = `https://app.customgpt.ai/api/v1/projects/${projectId}/conversations`;
  const headers = {
    authorization: "Bearer 3054|RnHVbOPRg3IZR5EWnFotdraPXulztrXzjI4RT8sv",
    accept: "application/json",
    "content-type": "application/json",
  };
  const data = {
    name: title,
  };
  try {
    const response = await axios.post(openai_url, data, {
      headers,
    });
    return response.data;
  } catch (error) {
    // Handle errors appropriately (e.g., logging, displaying error messages)
    console.error("Error fetching sessions:", error);
    throw error;
  }
};
export const getResponse = async (prompt, projectId, convId) => {
  const openai_url = `https://app.customgpt.ai/api/v1/projects/${projectId}/conversations/${convId}/messages?stream=true&lang=en`;
  const headers = {
    authorization: "Bearer 3054|RnHVbOPRg3IZR5EWnFotdraPXulztrXzjI4RT8sv",
    accept: "application/json",
    "content-type": "application/json",
  };
  const data = {
    prompt: prompt,
    response_source: "default",
    chatbot_model: "gpt-3.5-turbo",
  };
  const options = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(data),
  };
  try {
    let source = new SSE(openai_url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer 3054|RnHVbOPRg3IZR5EWnFotdraPXulztrXzjI4RT8sv",
      },
      method: "POST",
      payload: JSON.stringify(data),
    });

    return source;
  } catch (error) {
    // Handle errors appropriately (e.g., logging, displaying error messages)
    console.error("Error fetching sessions:", error);
    throw error;
  }
};
