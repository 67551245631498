import { getCurrentUser } from "../services/auth.service";
import LoginModal from "./Login";
import PaymentModal from "./Payment";

export default function LearnMoreDialog({ showModal, setShowModal }) {
  return (
    <div>
      {!getCurrentUser() ? (
        <LoginModal
          showModal={showModal}
          setShowModal={setShowModal}
          title={"Sign in to unlock Pro"}
        />
      ) : (
        getCurrentUser().user.plan === "none" && (
          <PaymentModal showModal={showModal} setShowModal={setShowModal} />
        )
      )}
    </div>
  );
}
